@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.account {
  &__description {
    color: $color-grey-80;
  }
}

.password-info {
  gap: 8px;

  &__icon {
    margin-top: 2px;
  }

  &__text {
    @include font(12px, 16px, 400);

    margin: 0;
  }
}

.password-toggle {
  cursor: pointer;
  width: 20px;
  height: 20px;

  &:hover {
    opacity: 0.8;
  }
}

.account-creation {
  .ui-input {
    &-wrapper__icon--secondary {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    &.ui-input-with-icon {
      padding: 24px 16px 8px;
    }
  }

  &__tooltip {
    text-align: left;

    &.ui-popper--max-w-md {
      @include on-tablet {
        max-width: 336px;
      }
    }
  }

  &__modal {
    background-color: $white;
    padding: 0 20px 16px;
  }
}
